import { TouchableOpacity, View, Image } from "react-native";
import Modal from "../../../../../../components/modal";
import Text from "../../../../../../components/Text";
import tailwind from "twrnc";
import NotesFileItem from "../../classes/upcoming_section/notes/notes_file_item";
import { useState } from "react";
import { TEACHERS, API } from "../../../../../../api/instance";
import moment from "moment";
import { AntDesign } from "@expo/vector-icons";
import NotesLinkItem from "../../classes/upcoming_section/notes/notes_link_item";
import Alert from "../../../../../../utils/alert";
import { useNavigation } from "@react-navigation/native";

const NotesModal = ({ open, setOpen, item }) => {
    // console.log("NotesModal : ", item)
    const [loading, setLoading] = useState(false);
    const { navigate } = useNavigation();

    const handleClick = async (note_item) => {
        let file_path = note_item.study_material_lesson.full_pdf_path
        let params = {
            "file_path": file_path
        }

        await TEACHERS.post(`/studymaterial/signedurl`, params)
            .then((response) => {
                let res = response.data.data

                if (res.url) {
                    setOpen(false)
                    return navigate("NoteView", { url: res.url })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <Modal visible={open} setVisible={setOpen} maxHeight={500}>
            <View>
                <View style={tailwind`flex flex-row justify-between items-center`}>
                    <Text style={tailwind`font-bold text-[16px]`}>Notes</Text>

                    <AntDesign
                        onPress={() => setOpen(false)}
                        name="close"
                        size={24}
                        color="black"
                    />
                </View>

                {item.calls &&
                    item.calls
                        .filter((x) => x.noteFiles[0] || x.noteLinks[0])
                        .map((call, i) => {
                            const date = moment.unix(call.slot / 1000).format("Do MMMM YYYY");
                            return (
                                <View key={i} style={tailwind``}>
                                    <Text style={tailwind`font-bold mb-3`}>{date}</Text>

                                    {call.noteFiles.map((note, i) => (
                                        <NotesFileItem key={i} note={note} setOpen={setOpen} />
                                    ))}

                                    {call.noteLinks.map((note, i) => (
                                        <NotesLinkItem key={i} note={note} setOpen={setOpen} />
                                    ))}
                                </View>
                            );
                        })}

                {item.classes &&
                    item.classes
                        .filter((x) => x.noteFiles[0] || x.noteLinks[0])
                        .map((call, i) => {
                            const date = moment.unix(call.slot / 1000).format("Do MMMM YYYY");
                            return (
                                <View key={i} style={tailwind``}>
                                    <Text style={tailwind`font-bold mb-3`}>{date}</Text>

                                    {call.noteFiles.map((note, i) => (
                                        <NotesFileItem key={i} note={note} setOpen={setOpen} />
                                    ))}

                                    {call.noteLinks.map((note, i) => (
                                        <NotesLinkItem key={i} note={note} setOpen={setOpen} />
                                    ))}
                                </View>
                            );
                        })}


                {item.is_bulk_booking && item.materials &&
                    item.materials
                        .map((booking, i) => {
                            const date = moment(item.booked_at, 'x').format("Do MMMM YYYY");
                            return (
                                <View key={i} style={tailwind``}>
                                    <Text style={tailwind`font-bold mb-3`}>{date}</Text>

                                    <View style={tailwind`flex flex-row justify-between mb-5 items-center`}>
                                        <View style={tailwind`flex flex-row items-center`}>
                                            <Image
                                                style={tailwind`w-[40px] h-[40px]`}
                                                source={require("../../../../../../../assets/ic_note_file.png")}
                                            />
                                            <Text style={tailwind`w-[180px] text-[12px] ml-3`}>
                                                {booking?.study_material_lesson?.source_name}
                                            </Text>
                                        </View>

                                        <TouchableOpacity
                                            onPress={() => handleClick(booking)}>
                                            <Text style={tailwind`text-[12px] font-bold text-[#2196F2]`}>View</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            );
                        })}


            </View>
        </Modal>
    );
};

export default NotesModal;
