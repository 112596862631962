import {
    View,
    TouchableOpacity,
    StyleSheet,
    Image,
    Pressable,
    ScrollView,
    FlatList,
    ActivityIndicator
} from "react-native";
import Modal from "../../../../components/modal";
import Text from "../../../../components/Text";
import tailwind from "twrnc";
import { useState, useEffect } from "react";
import { TEACHERS, API } from "../../../../api/instance";
import moment from "moment";
import { AntDesign } from "@expo/vector-icons";
import Alert from "../../../../utils/alert";
import { useNavigation } from "@react-navigation/native";
import useUser from "../../../../context/user";
import { height, isWeb, width } from "../../../../constants/constants";
let wd = width <= 500 ? width : 499.99
import Layout from "../../layout";
import PageHeader from "../../headers/page.header";
import RecordingModal from "./recording_modal";

export default function AllRecordings() {
    const { user } = useUser();
    const { navigate } = useNavigation();
    let [all_recordings, setAllRecordings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        getAllRecordings()
    }, []);

    async function getAllRecordings() {
        let params = {
            "phone": user.phone,
            "limit": 100
        }
        if (user.is_live_teacher)
            params.app_user_id = user._id

        setLoading(true)

        await TEACHERS.post(`/allrecordings`, params)
            .then((response) => {
                let res = response.data.data
                setAllRecordings(res)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setLoading(false)

            });
    }

    let [recording_item, setRecordingItem] = useState(null);
    let [recording_details, setRecordingDetails] = useState([]);

    const handleClick = async (item) => {
        setRecordingItem(item)
        if ((item.calls && item.calls.length > 0) || (item.classes && item.classes.length > 0) || (item.material_type && item.material_type == "recorded")) {
            if (item.material_type && item.material_type == "recorded") {
                let arr = (item.lesson_details && item.lesson_details.recording_paths) ? item.lesson_details.recordings_data : []
                setRecordingDetails(arr)
            } else {
                setRecordingDetails([])
            }
            setModalOpen(true)
        }
    };

    return (
        <Layout
            HeaderContent={
                <PageHeader backRoute="Home" pageTitle="All Recordings" />
            }
        // scrollView={false}
        >
            <View style={tailwind`flex flex-row w-full mt-4`}>

                <View style={styles.container}>
                    {
                        !loading && (<>

                            {
                                recording_item && <RecordingModal
                                    open={modalOpen}
                                    setOpen={setModalOpen}
                                    item={recording_item}
                                    recording_details={recording_details}
                                />
                            }

                            <FlatList
                                data={all_recordings}
                                numColumns={3}
                                contentContainerStyle={styles.listContainer}
                                columnWrapperStyle={styles.columnWrapper}
                                renderItem={({ item }) => (
                                    <View style={styles.card}>

                                        {(!item.material_type || item.material_type != "recorded") &&
                                            <>
                                                {item.course && <Image
                                                    source={{ uri: item?.course?.image }}
                                                    style={styles.image}
                                                    resizeMode="contain"
                                                />
                                                }
                                                {!item.course && <Image
                                                    style={tailwind`w-[70px] h-[70px] px-1`}
                                                    source={require("../../../../../assets/logo_with_shadow.png")}
                                                />}
                                            </>
                                        }

                                        {item.material_type && item.material_type == "recorded" &&

                                            <Image
                                                source={{ uri: item?.lesson_details?.course?.thumbnail_image_url }}
                                                style={styles.image}
                                                resizeMode="contain"
                                            />
                                        }


                                        {((!item.material_type || item.material_type != "recorded") && item.duration && item.name) &&
                                            <View style={tailwind`mt-1 flex-row items-center px-2`}>
                                                <Text style={tailwind`text-[12px] text-blue-500 font-semibold`}>
                                                    {item.name}
                                                </Text>
                                            </View>
                                        }

                                        {item.material_type && item.material_type == "recorded" &&
                                            <View style={tailwind`mt-1 flex-row items-center px-2`}>
                                                <Text style={tailwind`text-[12px] text-blue-500 font-semibold`}>
                                                    {item.lesson_details.course.name}
                                                </Text>
                                            </View>
                                        }

                                        <Text style={tailwind`mt-1 px-1 text-[12px] text-slate-500 text-center font-semibold`}>
                                            Recordings on {(item.total_session_recordings) ? item.total_session_recordings : 1} Topics
                                        </Text>

                                        <View style={tailwind`mt-1 px-1 flex-row justify-between w-full`}>
                                            {(!item.material_type || item.material_type != "recorded") &&
                                                <Text style={tailwind`text-[12px] text-slate-500 `}>
                                                    {(item.duration) ? "Group Classes" : "1 to 1 Classes"}
                                                </Text>
                                            }

                                            {item.material_type && item.material_type == "recorded" &&
                                                <Text style={tailwind`text-[12px] text-slate-500 `}>
                                                    Study Material
                                                </Text>
                                            }

                                            <TouchableOpacity
                                                onPress={() => handleClick(item)}

                                                style={tailwind``}
                                            >
                                                <Text
                                                    style={tailwind`text-center text-blue-600 underline text-[11px]`}
                                                >
                                                    View All
                                                </Text>
                                            </TouchableOpacity>
                                        </View>

                                    </View>


                                )}
                                keyExtractor={(item) => item.id.toString()}
                            />
                        </>)}

                    {loading &&
                        <View style={[tailwind`flex-1 bg-white`]}>
                            <View style={[tailwind`flex-1 justify-center items-center`]}>
                                <ActivityIndicator size="large" color="#60a5fa" />
                            </View>
                        </View>
                    }

                </View>
            </View>



        </Layout >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // padding: 10,
        backgroundColor: '#f8f8f8',
    },
    listContainer: {
        paddingBottom: 20,
    },
    columnWrapper: {
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },

    card: {
        backgroundColor: '#ffffff',
        margin: 5,
        // padding: 5,
        width: (wd - 70) / 3, // Adjust the width to ensure three columns
        height: 175,  // Fixed height for all cards
        justifyContent: 'center',
        alignItems: 'center',
        // borderRadius: 8,
        elevation: 3, // For shadow on Android
        shadowColor: '#000', // For shadow on iOS
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        // shadowRadius: 4,
    },
    image: {
        width: '100%',
        height: 80, // Adjust height as needed for better fit
        minHeight: 80
    },
    title: {
        padding: 5,
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#333',
        minHeight: 50
    },

});
