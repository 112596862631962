import React, { useState, useEffect } from "react";
import { Image, View, ScrollView, Pressable, TouchableOpacity } from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../../components/Text";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import useUser from "../../../../../../context/user";
import { TEACHERS, API } from "../../../../../../api/instance";
import NotesModal from "./notes_modal";

const Notes = ({ item, i }) => {
    const { navigate } = useNavigation();
    const { user } = useUser();
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
    }, []);

    const handleClick = async () => {
        if ((item.calls && item.calls.length > 0) || (item.classes && item.classes.length > 0) || item.is_bulk_booking) {
            setModalOpen(true)
        }
        else {
            let file_path = item.lesson_details.full_pdf_path
            let params = {
                "file_path": file_path
            }

            await TEACHERS.post(`/studymaterial/signedurl`, params)
                .then((response) => {
                    let res = response.data.data

                    if (res.url)
                        return navigate("NoteView", { url: res.url })
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <View key={i}
        >
            <NotesModal
                open={modalOpen}
                setOpen={setModalOpen}
                item={item}
            />
            <View>
                {
                    item.course_type && item.course_type == "study_material" && item.lesson_details &&
                    <View>
                        <Image
                            style={tailwind`w-full h-[100px]`}
                            source={{ uri: item?.lesson_details?.course?.thumbnail_image_url }}
                        />
                        <Text
                            style={[tailwind`capitalize text-slate-500 w-[200px] px-2 text-[14px] text-center mt-2 font-semibold`]}
                        >
                            {item.lesson_details.course.name}
                        </Text>
                    </View>
                }

                {
                    item.classes && item.classes.length > 0 &&
                    <View>
                        <Image
                            style={tailwind`w-[200px] h-[100px] px-4`}
                            source={{ uri: item?.course?.image }}
                        />
                        <Text
                            style={[tailwind`capitalize text-slate-500 w-[200px] px-2 text-[14px] text-center mt-2 font-semibold`]}
                        >
                            {item.name}
                        </Text>
                    </View>
                }

                {
                    item.calls && item.calls.length > 0 && item.course_booking && item.course_booking.course &&
                    <View>
                        <Image
                            style={tailwind`w-[200px] h-[100px] px-4`}
                            source={{ uri: item?.course_booking?.course?.image }}
                        />
                        <Text
                            style={[tailwind`capitalize text-slate-500 w-[200px] px-2 text-[14px] text-center mt-2 font-semibold`]}
                        >
                            {item.course_booking.course.title}
                        </Text>
                    </View>
                }



                {
                    item.course_type && item.course_type == "study_material" && item.lesson_details && <Text style={tailwind`mt-1 text-[14px] text-slate-500 w-[200px] text-center text-blue-400`}>
                        Notes on {(item.is_bulk_booking && item.materials) ? item.materials.length : 1} topics
                    </Text>
                }

                {
                    item.classes && item.classes.length > 0 && <Text style={tailwind`mt-1 text-[14px] text-slate-500 w-[200px] text-center text-blue-400`}>
                        Notes on {item.total_calls_note_attached} topics
                    </Text>
                }

                {
                    item.calls && item.calls.length > 0 && <Text style={tailwind`mt-1 text-[14px] text-slate-500 w-[200px] text-center text-blue-400`}>
                        Notes on {item.total_calls_note_attached} topics
                    </Text>
                }

                <TouchableOpacity
                    // onPress={() => setModalOpen(true)}
                    onPress={() => handleClick()}

                    style={tailwind`mt-1 text-[11px] w-[200px] text-center text-blue-600 underline`}
                >
                    <Text
                        style={tailwind`text-center text-blue-600 underline`}
                    >
                        View All
                    </Text>
                </TouchableOpacity>

            </View>


        </View>
    );

};

export default Notes;
